.tpsbanner {
	background-image: url(./images/_0001_TPS-TopBanner.png);
	background-size: cover !important;
	background-position: center;
}
.udibanner {
	border-top: 15px solid purple;
	border-bottom: 15px solid purple;
	background-color: #19113b;
	background-image: url(./images/PixelBG.jpg);
	background-size: cover !important;
	background-repeat: no-repeat;
	background-position: center;
}
.jumbotron {
	min-height: 320px;
}
