html {
	background-color: #1d0036;
}
.body {
	background-color: #1d0036;
}
#ImgZoom a img:hover {
	transform: scale(1.1);
}

#ImgZoom a img {
	transition: transform 0.2s ease;
}

#ImgZoom a button:hover {
	transform: scale(1.1);
}

#ImgZoom a button {
	transition: transform 0.2s ease;
}

.jumbotron {
	min-height: 320px;
}
.crt-post-content:hover {
	color: #ff7a59;
	border: #33007f solid 1px;
	background: #1a0040;
}
.crt-post:hover {
	transform: scale(
		1.05
	); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.crt-post .crt-post-text a:hover {
	color: #fff !important;
}
a {
	color: #5333b7;
}
.jumbotron {
	padding-top: 0px;
	padding-bottom: 0px;
	margin-bottom: 0px;
}
.auto-center-col {
	max-width: 100%;
	margin: 0 auto;
}
.card {
	max-width: 100% !important;
	margin: 0 auto;
	padding-bottom: 35px;
	background-color: #1d0036 !important;
	color: white;
}
.card2 {
	max-width: 100% !important;
	margin: 0 auto;
	padding-bottom: 5px;
}
.card3 {
	max-width: 800px !important;
	margin: 0 auto;
	padding-bottom: 5px;
}
.card4 {
	max-width: 720px !important;
	margin: 0 auto;
	padding-bottom: 25px;
	padding-top: 15px;
}
.card5 {
	max-width: 720px !important;
	margin: 0 auto;
	height: 66px;
	padding-bottom: 25px;
	padding-top: 15px;
}
.navbar {
	margin-bottom: 0px;
}
.card-img-top {
	width: 100%;
}
h5 {
	font-size: 14px;
	font-weight: 700;
}

/* Zoom In #2 */
.hover02 figure img {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: 0.3s ease-in-out;
	transition: 0.3s ease-in-out;
}
.hover02 figure:hover img {
	-webkit-transform: scale(1.15);
	transform: scale(1.15);
}

.divTable {
	display: table;
	width: 330px;
}
.divTableRow {
	display: table-row;
}
.divTableHeading {
	display: table-header-group;
}
.divTableCell,
.divTableHead {
	border: none;
	display: table-cell;
	padding: 3px 10px;
}
.divTableHeading {
	display: table-header-group;
	font-weight: bold;
}
.divTableFoot {
	display: table-footer-group;
	font-weight: bold;
}
.divTableBody {
	display: table-row-group;
}
.container-mainContent {
	margin-top: 150px;
}
.row-mnu {
	max-width: 1000px;
}

.card-Access {
	border-radius: 10px;
	overflow: hidden;
	cursor: pointer;
}

.dark-pricing-table {
	background-color: #282b30;
}

.table-button {
	background-color: #3d4047;
	border-radius: 10px;
}

.or-text {
	color: #9d9da1;
	font-weight: 700;
}

.hand-image {
	position: absolute;
	left: -120px;
	top: 40px;
	transition: all 0.4s;
}

.card:hover .hand-image {
	left: -70px;
	top: 19px;
}

.soft-pricing-table {
	background-color: #d0a784;
	border: none;
}
.ytembedd {
	position: relative; 
	padding-bottom: 56.25%; 
	padding-top: 30px; 
	height: 0; 
	overflow: hidden; 
}
.ytembedd iframe, .ytembedd object, .ytembedd embed { 
	position: absolute; 
	top: 0; 
	left: 0; 
	width: 100%; 
	height: 100%; 
}
.ytembeddnfo {
	display: block;
	position: relative;
	padding: 25px;
}
.im {
	transition: transform .2s;
	margin: 0 auto;
  }
	
  .im:hover {
	transform: scale(1.1);
  }